interface FeatureFlagsDefinition {
  feature: string
}

const FEATURE_FLAGS = [
  {
    feature: 'hrt_unit_name_filter',
  },

  {
    feature: 'hrt_search_parameters',
  },
  {
    feature: 'hrt_multi_species',
  },
  {
    feature: 'hrt_total_results',
  },
] as const satisfies readonly FeatureFlagsDefinition[]

export type FeatureFlagsKey = (typeof FEATURE_FLAGS)[number]['feature']

export type FeatureFlags = Record<FeatureFlagsKey, string | boolean>

export const FEATURES = FEATURE_FLAGS.map((obj) => obj.feature)
